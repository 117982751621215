<template>
    <v-container class="fill-height d-flex flex-column justify-center align-center">
        <v-img
            contain
            :src="require('@/assets/images/logos/characters-home-wireframe.svg')"
            :lazy-src="require('@/assets/images/logos/characters-home-wireframe.svg')"
        />
        <div class="mt-8">
            <span
                :style="`font-size: 3.5vw; color: ${$store.getters.getColors.primary}`"
                class="heading text-uppercase font-weight-medium"
            >
                Feondi Admin
            </span>
        </div>
        <div>
            <span
                :style="`font-size: 2.5vw; color: ${$store.getters.getColors.secondary2}`"
                class="heading font-weight-medium"
            >
                V2 of knowledge
            </span>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'Home',
    components: {},
    metaInfo: {
        title: 'Home',
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => vm.$store.commit('RESET_PROP', 'members'))
    }
}
</script>
